import React, { useEffect } from "react";
import "./App.css";
import NavHeader from "./navHeader.js";
import Strands from "./rentia22/strands/strands.js";
import Connections from "./rentia22/connections.js";
import Wordle from "./rentia22/wordle.js";
import Wortel from "./rentia22/wortel.js";
import Watse from "./rentia22/watse.js";
import FinalPage from "./rentia22/final.js";
import Counter from "./games/counter.js";
import ShoppingList from "./lists/shoppingList.js";
import MoviesList from "./lists/moviesList.js";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const {
    location: { pathname },
  } = window;

  useEffect(() => {
    if (pathname === "/rentia22") window.location.href = "/rentia22/strands";
  }, []);

  return (
    <div className='App' style={{ minWidth: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
        <div><NavHeader /></div>
        {pathname.includes("rentia22") && <Rentia22 />}
        {pathname.includes("games") && <Games />}
        {pathname.includes("shopping-list") && <ShoppingList />}
        {pathname.includes("movies") && <MoviesList />}
      </div>
    </div>
  );
}

function Rentia22() {
  const {
    location: { pathname },
  } = window;

  return (
    <div>
      {pathname.includes("strands") && <Strands />}
      {pathname.includes("connections") && <Connections />}
      {pathname.includes("wordle") && <Wordle />}
      {pathname.includes("wortel") && <Wortel />}
      {pathname.includes("watse") && <Watse />}
      {pathname.includes("final") && <FinalPage />}
    </div>
  );
}

function Games() {
  const {
    location: { pathname },
  } = window;

  return (
    <div>
      {pathname.includes("counter") && <Counter />}
    </div>
  );
}

export default App;