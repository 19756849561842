import React, { useState, useEffect } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import './navHeader.css';

const NavHeader = () => {
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleToggle = () => setShow(!show);

  const navigateToRentia22 = () => {
    window.location.href = '/rentia22';
  };

  const navigateToGameCounter = () => {
    window.location.href = '/games/counter';
  };

  const navigateToShoppingList = () => {
    window.location.href = '/shopping-list';
  };

  const navigateToMovies = () => {
    window.location.href = '/movies';
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Button className="hamburger-button" onClick={handleToggle}>
            <FaBars />
          </Button>

          <Offcanvas show={show} onHide={handleToggle} placement="start">
            <Offcanvas.Header closeButton />
            <Offcanvas.Body>
              <nav className="drawer-nav">
                <button className="colorful-button" onClick={navigateToRentia22}>
                  <span className="rainbow-text">Rentia 22 🎈</span>
                </button>
                <button className="grey-button" onClick={navigateToGameCounter}>
                  Game Counter
                </button>
                <button className="green-button" onClick={navigateToShoppingList}>
                  Shopping List 🛒
                </button>
                <button className="black-button" onClick={navigateToMovies}>
                  Movies 🎬
                </button>
              </nav>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        <nav>
          <button className="colorful-button" onClick={navigateToRentia22}>
            <span className="rainbow-text">Rentia 22 🎈</span>
          </button>
          <button className="grey-button" onClick={navigateToGameCounter}>
            Game Counter
          </button>
          <button className="green-button" onClick={navigateToShoppingList}>
            Shopping List 🛒
          </button>
          <button className="black-button" onClick={navigateToMovies}>
            Movies 🎬
          </button>
        </nav>
      )}
    </>
  );
};

export default NavHeader;