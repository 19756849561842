import React, { useState, useEffect } from "react";
import { FaSyncAlt, FaCheckCircle, FaTrashAlt, FaEdit, FaCheck } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./shoppingList.css";
import ListTemplate from "./template.js";

const MoviesList = () => {
    return ListTemplate({title: "Movies 🎬", currentItemsName: "To watch 👀", currentItemsKey: "toWatchItems", listKey: "moviesList", 
    // syncCurrentItemsFunction: ({key, currentItems, archiveItems}) => syncToWatchFunction({key, toWatchItems: currentItems, archiveItems}), fetchCurrentItemsFunction: console.log 
    });
}

export default MoviesList;