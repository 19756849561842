import React, { useState, useEffect } from "react";
import { FaSyncAlt, FaCheckCircle, FaTrashAlt, FaEdit, FaCheck } from "react-icons/fa";
import { getFunctions, httpsCallable } from "firebase/functions";
import "./template.css";

const ListTemplate = ({ title, currentItemsTitle,
    listKey,
    currentItemsName, currentItemsKey, archiveItemsKey = "archiveItems", 
 }) => {
  const functions = getFunctions();
  const syncListFunction = httpsCallable(functions, "syncList");
  const fetchListDataFunction = httpsCallable(functions, "fetchListData");

  const checkKeyFunction = httpsCallable(functions, 'checkKey');

  const [currentItems, setCurrentItems] = useState([]);
  const [archiveItems, setArchiveItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [input, setInput] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [syncing, setSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [updates, setUpdates] = useState({ currentItems: 0, archive: 0 });
  const [showDialog, setShowDialog] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editInput, setEditInput] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {
    const storedKey = localStorage.getItem("lists-secret-key");
    if (storedKey) {
      checkKey(storedKey);
    } else {
      promptForKey();
    }

    const storedShowEmojis = localStorage.getItem("show-emojis") === "true";
    setShowEmojis(storedShowEmojis);
  }, []);

  useEffect(() => {
    if (secretKey) {
      fetchListData();
    }
  }, [secretKey]);

  useEffect(() => {
    if (secretKey) {
      syncList();
    }
  }, [currentItems, archiveItems]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForUpdates();
    }, 15000);
    return () => clearInterval(interval);
  }, [secretKey, currentItems, archiveItems]);

  const checkKey = async (inputKey) => {
    try {
      await checkKeyFunction({ permissionKey: inputKey });
      setSecretKey(inputKey);
      localStorage.setItem("lists-secret-key", inputKey);
    } catch (error) {
      alert("The key is incorrect");
      promptForKey();
    }
  };

  const promptForKey = () => {
    const inputKey = prompt("Please enter the secret key:");
    if (inputKey) {
      checkKey(inputKey);
    }
  };

  const fetchListData = async () => {
    try {
      const result = await fetchListDataFunction({ secretKey, listKey, currentItemsKey, archiveItemsKey });
      setCurrentItems(result.data[currentItemsKey] || []);
      setArchiveItems(result.data[archiveItemsKey] || []);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch current items data", error);
      setLoading(false);
    }
  };

  const checkForUpdates = async () => {
    try {
      const result = await fetchListDataFunction({ secretKey, listKey, currentItemsKey, archiveItemsKey });
      const newCurrentItems = result.data[currentItemsKey] || [];
      const newArchiveItems = result.data[archiveItemsKey] || [];
      let currentItemUpdates = 0;
      let archiveUpdates = 0;

      if (newCurrentItems.length !== currentItems.length) {
        currentItemUpdates = Math.abs(newCurrentItems.length - currentItems.length);
      }

      if (newArchiveItems.length !== archiveItems.length) {
        archiveUpdates = Math.abs(newArchiveItems.length - archiveItems.length);
      }

      if (currentItemUpdates > 0 || archiveUpdates > 0) {
        setUpdates({ currentItems: currentItemUpdates, archive: archiveUpdates });
        setShowDialog(true);
      }
    } catch (error) {
      console.error("Failed to check for updates", error);
    }
  };

  const pullChanges = async () => {
    await fetchListData();
    setShowDialog(false);
  };

  const addItem = () => {
    if (input.trim()) {
      setCurrentItems([...currentItems, { name: input.trim(), emoji: "" }]);
      setInput("");
    }
  };

  const currentItemsToArchive = (item) => {
    setCurrentItems(currentItems.filter((currentItem) => currentItem.name !== item.name));
    setArchiveItems([...archiveItems, item]);
  };

  const archiveToCurrentItems = (item) => {
    setArchiveItems(archiveItems.filter((archiveItem) => archiveItem.name !== item.name));
    setCurrentItems([...currentItems, item]);
  };

  const removeItem = (item, fromCurrentItems) => {
    if (window.confirm("Are you SURE? This will remove this item permanently!")) {
      setDeletedItems([...deletedItems, item]);
      if (fromCurrentItems) {
        setCurrentItems(currentItems.filter((currentItem) => currentItem.name !== item.name));
      } else {
        setArchiveItems(archiveItems.filter((archiveItem) => archiveItem.name !== item.name));
      }
    }
  };

  const checkAllItems = () => {
    if (window.confirm("Are you sure you want to check all items?")) {
      setArchiveItems([...archiveItems, ...currentItems]);
      setCurrentItems([]);
    }
  };

  const addAllToCurrentItems = () => {
    if (window.confirm("Are you sure you want to add all items to the current items list?")) {
      setCurrentItems([...currentItems, ...archiveItems]);
      setArchiveItems([]);
    }
  };

  const clearAllArchive = () => {
    if (window.confirm("Are you SURE? This will remove everything from your Archive!")) {
      setDeletedItems([...deletedItems, ...archiveItems]);
      setArchiveItems([]);
    }
  };

  const restoreDeletedItems = () => {
    setCurrentItems([...currentItems, ...deletedItems]);
    setDeletedItems([]);
  };

  const syncList = async () => {
    if (secretKey && secretKey !== "") {
      setSyncing(true);
      setSyncStatus("syncing");
      try {
        await syncListFunction({ secretKey, listKey, currentItems, currentItemsKey, archiveItems, archiveItemsKey });
        setSyncing(false);
        setSyncStatus("synced");
      } catch (error) {
        setSyncing(false);
        setSyncStatus("failed");
        console.error("Failed to sync current items", error);
      }
    }
  };

  const startEditing = (item) => {
    setEditingItem(item);
    setEditInput(item.name);
  };

  const saveEdit = (oldItem) => {
    const updatedCurrentItems = currentItems.map((item) => (item.name === oldItem.name ? { ...item, name: editInput } : item));
    setCurrentItems(updatedCurrentItems);
    setEditingItem(null);
    setEditInput("");
    syncList();
  };

  const toggleShowEmojis = () => {
    const newShowEmojis = !showEmojis;
    setShowEmojis(newShowEmojis);
    localStorage.setItem("show-emojis", newShowEmojis);
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <FaSyncAlt className="icon-spin" size={50} />
      </div>
    );
  }

  return (
    <div className='shopping-list'>
      <h1>{title}</h1>
      <section>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2>{currentItemsTitle}</h2>
          <div style={{ position: "relative" }}>
            <button
              onClick={toggleShowEmojis}
              className='toggle-emoji-button'
              style={{ marginLeft: "10px", color: "darkorange", borderColor: "darkorange", borderRadius: 5, backgroundColor: 'lightyellow' }}
              data-tip
              data-for="showEmojisTooltip"
            >
              {showEmojis ? "😃 Hide" : "😃 Show"}
            </button>
          </div>
        </div>
        {deletedItems.length > 0 && (
          <button onClick={restoreDeletedItems} className='restore-button'>
            <FaTrashAlt /> Restore {deletedItems.length} Deleted Item{deletedItems.length > 1 ? "s" : ""}
          </button>
        )}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px", minWidth: "300px" }}>
          <button onClick={syncList} className='sync-button'>
            {syncing ? (
              <span className='sync-status syncing'>
                <div style={{ marginRight: "5px" }}>
                  <FaSyncAlt className='icon-spin' />
                </div>
                Syncing
              </span>
            ) : syncStatus === "synced" ? (
              <span className='sync-status synced'>
                <div style={{ marginRight: "5px" }}>
                  <FaCheckCircle />
                </div>
                All changes synced
              </span>
            ) : (
              <span className='sync-status'>
                <div style={{ marginRight: "5px" }}>
                  <FaSyncAlt />
                </div>
                Sync
              </span>
            )}
          </button>
        </div>
        {currentItems.length === 0 ? (
          <p>No items here!</p>
        ) : (
          <>
            <ul>
              {currentItems.map((item, index) => (
                <li key={index}>
                  <div className='item-content'>
                    <input type='checkbox' className='large-checkbox' checked={false} onChange={() => currentItemsToArchive(item)} />
                    <div className="item-content-text">
                    {editingItem === item ? (
                      <div className='edit-container'>
                        <input
                          type='text'
                          value={editInput}
                          onChange={(e) => setEditInput(e.target.value)}
                          className='edit-input'
                          onKeyDown={(e) => e.key === "Enter" && saveEdit(item)}
                        />
                        <button onClick={() => saveEdit(item)} className='save-button'>
                          <FaCheck />
                        </button>
                      </div>
                    ) : (
                      <>
                        {item.name} {showEmojis && item.emoji}
                      </>
                    )}</div>
                  </div>
                  <div className='item-actions'>
                    <button onClick={() => startEditing(item)} className='edit-button'>
                      <FaEdit />
                    </button>
                    <button className='remove-button' onClick={() => removeItem(item, true)}>
                      <FaTrashAlt />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className='add-item' style={{marginBottom: "10px"}}>
        <input
          type='text'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && addItem()}
          placeholder='Add an item'
        />
          <button onClick={addItem}>➕</button>
        </div>
        {currentItems.length > 0 && (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <button onClick={checkAllItems}><span style={{marginRight: "5px"}}>✅</span> Check all above</button>
          </div>
        )}
      </section>
      <section>
        <h2>Archive 📦</h2>
        {archiveItems.length === 0 ? (
          <p>No items here!</p>
        ) : (
          <>
            <button onClick={addAllToCurrentItems}>Add All to {currentItemsName}</button>
            <button onClick={clearAllArchive} className='clear-all-button'>
              <FaTrashAlt style={{ color: "red" }} /> Clear All
            </button>
            <ul>
              {archiveItems.map((item, index) => (
                <li key={index}>
                  <div className='item-content'>
                    <input type='checkbox' className='large-checkbox' checked onChange={() => archiveToCurrentItems(item)} />
                    <span className='strikethrough'>{item.name} {showEmojis && item.emoji}</span>
                  </div>
                  <div className='item-actions'>
                    <button className='remove-button' onClick={() => removeItem(item, false)}>
                      <FaTrashAlt />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </section>
      {showDialog && (
        <>
          <div className='overlay'></div>
          <div className='update-dialog'>
            <p>Someone else updated while you were busy:</p>
            {updates.currentItems > 0 && <p>{currentItemsName} ({updates.currentItems} items)</p>}
            {updates.archive > 0 && <p>Archive ({updates.archive} items)</p>}
            <button onClick={pullChanges}>Pull changes now</button>
          </div>
        </>
      )}
   
    </div>
  );
};

export default ListTemplate;